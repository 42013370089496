import React from 'react';
import cx from 'classnames';
import sanityImgUtil from '../utils/sanityImgUtil';
import sanityImgSrcSetUtil from '../utils/sanityImgSrcSetUtil';
import { curlyQuotes } from '../utils/text';
import { useUI } from '../providers/UIProvider';

import { Img } from '../components/base';

import { Position, Image, Rules } from '../sharedTypes';
import { Theme } from '../types';
import { BlockContent } from './BlockContent';
import { Link } from 'components/Link';

interface InsetImgProps {
  position: Position;
  image: Image;
  rules?: Rules;
  url?: string;
  openCarouselOverlay?: (activeImageId: string) => void;
}

export const InsetImg: React.FC<InsetImgProps> = (props) => {
  const { theme } = useUI();
  const {
    position,
    image: { caption, credit, alt, rteCredit },
    image,
    rules,
    url,
    openCarouselOverlay,
  } = props;

  return (
    <div
      className={cx(`InsetImg InsetImg--${position} InsetImg--${rules} `, {
        'mb_5 pb2_25 pt_75': rules === 'show-rules',
        [`InsetImg--${position}-black`]: theme === Theme.Black,
      })}
      onClick={
        openCarouselOverlay ? () => openCarouselOverlay(image.id) : undefined
      }
    >
      <div className="InsetImg__container relative w100">
        {url ? (
          <Link
            to={url}
            target="_blank"
            className={cx({ pointer: openCarouselOverlay })}
          >
            <Img
              alt={alt || caption || ''}
              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1200px"
              src={sanityImgUtil(image, 1200)}
              srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1200)}
              dimensions={image.metadata && image.metadata.dimensions}
              crop={image.crop}
              isFullBleed={position === Position.FULL_BLEED}
            />
          </Link>
        ) : (
          <Img
            alt={alt || caption || ''}
            sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1200px"
            src={sanityImgUtil(image, 1200)}
            srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1200)}
            dimensions={image.metadata && image.metadata.dimensions}
            crop={image.crop}
            isFullBleed={position === Position.FULL_BLEED}
          />
        )}
      </div>

      {(!!caption || !!credit || !!rteCredit) && (
        <div className="InsetImg__caption-container graebenbach font-300">
          {caption && (
            <span className="InsetImg__caption color-gray-darkest text-article-details-xs font-300">
              {curlyQuotes(caption)}
            </span>
          )}
          {rteCredit ? (
            <span className="ImageCreditLink inline-block ml_5 color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md transition-shorter">
              <BlockContent blocks={rteCredit} />
            </span>
          ) : credit ? (
            <span className="InsetImg__credit ml_5 color-gray text-article-details-xxs font-300 uppercase">
              {curlyQuotes(credit)}
            </span>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

export default InsetImg;
