import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import { useCurrentURL, useFormattedTimeStamp } from 'src/hooks';
import ReactPlayer from 'react-player';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import Controls from './Controls';
import ShareAudio from './Share';
import Timestamp from './Timestamp';
import Slider from 'components/base/Slider';
import Volume from './Volume';
import PodcastInfo from './PodcastInfo';
import MainBox from './MainBox';
import AudioSlider from './AudioSlider';
import { AudioPlayerType } from 'sharedTypes';

import { useUI } from 'providers/UIProvider';
import { Theme } from 'types';
import { useAudioPlaying } from 'src/hooks/useAudio';

interface AudioPlayerProps {
  content: AudioPlayerType;
  className?: string;
  isFeatureArticleContent?: boolean;
  featureArticleBackgroundColor?: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  content,
  className,
  isFeatureArticleContent,
  featureArticleBackgroundColor,
}) => {
  const currentURL = useCurrentURL();
  const { theme } = useUI();

  const {
    playerRef,
    isPlaying,
    togglePlaying,
    dragActiveValue,
    setDragActiveValue,
    handleProgress,
    onBuffer,
    onBufferEnd,
    onBeforeChange,
    onAfterChange,
  } = useAudioPlaying();

  const [volume, setVolume] = useState(0.5);

  const formattedTime = useFormattedTimeStamp({
    playedDuration: dragActiveValue,
    totalDuration: playerRef.current?.getDuration() || 0,
  });

  const isPodcastPlayer = content?.linkedPodcastEpisode;

  configureAnchors({
    offset: -200,
    scrollDuration: 1000,
  });

  // Dynamically update the CSS variable for the feature article background color if it is not using the sand theme
  useEffect(() => {
    const defaultFeatureBackgroundColor = '#f1d5b9';
    const shouldUpdateBackgroundColor =
      isFeatureArticleContent &&
      featureArticleBackgroundColor &&
      featureArticleBackgroundColor !== defaultFeatureBackgroundColor;

    if (shouldUpdateBackgroundColor) {
      document.documentElement.style.setProperty(
        '--off-white',
        featureArticleBackgroundColor
      );
    }
  }, [isFeatureArticleContent, featureArticleBackgroundColor]);

  return (
    <ScrollableAnchor id={content.id}>
      <div
        className={cx(['AudioPlayer', className], {
          'dark-player': theme === Theme.Black,
          'sand-player':
            theme === Theme.Sand ||
            (isFeatureArticleContent &&
              featureArticleBackgroundColor === '#f1d5b9'),
          'simple-player': !isPodcastPlayer,
          'podcast-player': isPodcastPlayer,
        })}
      >
        {isPodcastPlayer && (
          <div className="flex w100">
            <PodcastInfo info={content.linkedPodcastEpisode}>
              <div className="small-timestamp">{formattedTime}</div>
            </PodcastInfo>
          </div>
        )}

        <div className="flex w100 player-slider">
          <AudioSlider
            value={dragActiveValue}
            max={playerRef.current?.getDuration() || 1}
            onChange={setDragActiveValue}
            onBeforeChange={onBeforeChange}
            onAfterChange={onAfterChange}
          />
        </div>

        {!isPodcastPlayer && (
          <div className="flex w100">
            <div className="small-audio-title w100">
              <span className="plain-title">{content.title}</span>
              <div className="small-timestamp">{formattedTime}</div>
            </div>
          </div>
        )}

        <ReactPlayer
          ref={playerRef}
          style={{ display: 'none' }}
          playing={isPlaying}
          onProgress={handleProgress}
          volume={volume}
          onBuffer={onBuffer}
          onBufferEnd={onBufferEnd}
          url={
            isPodcastPlayer
              ? content.linkedPodcastEpisode.podcastAudioLink
              : content.mp3Link
          }
        />

        <div className="flex w100">
          <Controls
            {...{
              playerRef,
              isPlaying,
              togglePlaying,
            }}
          />

          <Timestamp time={formattedTime} />

          <MainBox
            isPodcastPlayer={!!isPodcastPlayer}
            title={content.title}
            applePodcastLink={
              content.linkedPodcastEpisode?.podcast.applePodcastLink
            }
            spotifyPodcastLink={
              content.linkedPodcastEpisode?.podcast.spotifyPodcastLink
            }
            rssLink={content.linkedPodcastEpisode?.podcast.rssLink}
          />

          <Volume>
            <Slider
              className="volume-slider"
              value={volume}
              onChange={setVolume}
            />
          </Volume>
          {isPodcastPlayer ? (
            <ShareAudio
              link={`https://www.tabletmag.com/podcasts/${content.linkedPodcastEpisode.podcast.seriesSlug}/${content.linkedPodcastEpisode.slug}`}
            />
          ) : (
            <ShareAudio link={`${currentURL}#${content.id}`} />
          )}
        </div>

        {isPodcastPlayer && (
          <div className="flex w100">
            <MainBox
              className="small-screen"
              isPodcastPlayer={!!isPodcastPlayer}
              title={content.title}
              applePodcastLink={
                content.linkedPodcastEpisode?.podcast.applePodcastLink
              }
              spotifyPodcastLink={
                content.linkedPodcastEpisode?.podcast.spotifyPodcastLink
              }
              rssLink={content.linkedPodcastEpisode?.podcast.rssLink}
            />
          </div>
        )}
      </div>
    </ScrollableAnchor>
  );
};

export default AudioPlayer;
