import React, { Component, createRef, RefObject } from 'react';
import get from 'lodash/get';
import cx from 'classnames';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { curlyQuotes } from 'utils/text';

interface Props {
  title?: string;
  src?: string;
  html?: string;
  size?: 'large' | 'medium' | 'small-left' | 'small-right';
  caption?: string;
  attribution?: string;
  className?: string;
}

interface State {
  ratio: number | null;
}

class EmbedMedia extends Component<Props> {
  mediaRef: RefObject<HTMLDivElement> = createRef();
  state: State = {
    ratio: null,
  };

  componentDidMount() {
    setTimeout(this.getIframeRatio, 0);
  }

  getIframeRatio = () => {
    const iframeContainer: HTMLDivElement | null =
      this.mediaRef.current && this.mediaRef.current;
    const iframe: HTMLIFrameElement | null =
      iframeContainer && (iframeContainer.children[0] as HTMLIFrameElement);

    if (iframeContainer && iframe && iframe.contentWindow) {
      const height = get(iframe, 'clientHeight');
      const width = get(iframe, 'clientWidth');
      const ratio = (height / width) * 100;

      this.setState({ ratio });
    }
  };

  render() {
    const {
      title,
      src,
      html,
      caption,
      attribution,
      size,
      className = '',
    } = this.props;
    const { ratio } = this.state;
    const computedSize = size || 'large';

    const isInstagramPost =
      this.mediaRef.current?.innerHTML.includes('instagram-media');

    if (
      src &&
      src.includes('https://twitter.com/') &&
      src.includes('/status/')
    ) {
      const tweet = src.split('/')[src.split('/').length - 1];
      return (
        <div
          ref={this.mediaRef}
          style={ratio ? { paddingTop: `${ratio}%` } : {}}
          className={cx(
            `EmbedMedia EmbedMedia__media EmbedMedia--twitter relative flex items-center justify-center mb1_5 EmbedMedia--${ratio}`,
            className,
            {
              'EmbedMedia__media--active overflow-hidden': ratio,
            }
          )}
        >
          <TwitterTweetEmbed tweetId={tweet} />
        </div>
      );
    }

    if (html) {
      return (
        <div
          className={cx(`EmbedMedia col-12`, computedSize, className, {
            'xxl:col-10 mxauto': computedSize === 'large',
            'lg:col-10 xl-wide:col-8 mxauto': computedSize === 'medium',
            'lg:col-6 left': computedSize === 'small-left',
            'lg:col-6 right': computedSize === 'small-right',
          })}
        >
          <div
            ref={this.mediaRef}
            style={ratio && !isInstagramPost ? { paddingTop: `${ratio}%` } : {}}
            className={cx(
              `EmbedMedia__media relative flex items-center justify-center mb1_5 px1`,
              className,
              {
                'EmbedMedia__media--active overflow-hidden': ratio,
              }
            )}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {caption ? (
            <div className="EmbedMedia__caption color-gray-darkest text-article-details-xs font-400 graebenbach">
              {curlyQuotes(caption)}
            </div>
          ) : null}
          {attribution ? (
            <div className="EmbedMedia__caption__attribution color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
              {curlyQuotes(attribution)}
            </div>
          ) : null}
        </div>
      );
    } else if (src) {
      return (
        <iframe
          className={className}
          title={title}
          src={src}
          width="100%"
          scrolling="no"
        />
      );
    }

    return null;
  }
}

export default EmbedMedia;
