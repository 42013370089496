import { RouteMap } from 'constants/RouteMap';

const generateRelatedArticlesUrl = (
  sectionSlug: string,
  slug: string
): string => {
  return `${RouteMap.ARTICLE.base}/${sectionSlug}/articles/${slug}`;
};

export default generateRelatedArticlesUrl;
