import React, { useEffect } from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { ArticleLink, Collection } from 'sharedTypes';
import Language from 'constants/Language';
import { Button } from './base';
import ApiClient from 'lib/ApiClient';
import articlesByDate from 'sanitizers/articlesByDate';

interface Props {
  collectionId: string;
}

const RelatedArticlesByCollection: React.FC<Props> = ({ collectionId }) => {
  const [collection, setCollection] = React.useState<Collection>({
    type: 'collection',
    id: '',
    title: '',
    description: '',
    image: undefined,
    slug: '',
    featuredArticles: [],
  });
  const [articles, setArticles] = React.useState<ArticleLink[]>([]);
  const { title, description, slug } = collection;

  useEffect(() => {
    const fetchCollection = async () => {
      const coll = await ApiClient.fetchCollectionById(collectionId);

      setCollection(coll);
    };
    fetchCollection();

    const fetchArticles = async () => {
      const articles = await ApiClient.fetchArticlesByCollectionRef(
        collectionId
      );
      setArticles(articles);
    };
    fetchArticles();
  }, [collectionId]);

  if (!articles || !collection) {
    return null;
  }

  return (
    <div className="RelatedArticlesByCollection no-display-in-print">
      <div className="header border-bottom-black pb3">
        <div className="tag graebenbach">collection</div>

        <div className="title graebenbach mb1_5">{title}</div>
        <div className="description graebenbach mb1_5">{description}</div>

        <Button
          ariaLabel={Language.t('Global.articleButtonAriaLabel', {
            title,
          })}
          to={'/collections/' + slug}
          wrap={true}
        >
          <div className="TextIntroStoryTeaserWidget__continue-reading color-black graebenbach RelatedArticlesByCollection__text">
            {Language.t('Global.seeFullCollection')} &rarr;&#xFE0E;
          </div>
        </Button>
      </div>

      <div className="articles inner-content-max-width mxauto">
        {articlesByDate(articles)
          .slice(0, 3)
          .map((article) => (
            <StoryTeaserSwitch
              key={article.id}
              teaser={'teaser-text-md'}
              article={article}
            />
          ))}
      </div>
    </div>
  );
};

export default RelatedArticlesByCollection;
