// @ts-nocheck
import React, { Component } from 'react';
import * as cheerio from 'cheerio';

import ShareButton from 'components/ShareButton';
import { Button } from 'components/base';

import Language from 'constants/Language';

interface Props {
  title?: string;
  htmlContent: string;
}

const sanitizeRecipeHtml = ($: CheerioStatic): CheerioStatic => {
  $('script').remove();
  $(
    'div.narrow-only.medium-only[style="height:250px; width:300px !important; margin:1em auto;"]'
  ).remove();

  return $;
};

class LegacyRecipe extends Component<Props> {
  handlePrintButtonClick = () => {
    window.print();
  };

  render() {
    const { title, htmlContent } = this.props;

    const $: CheerioStatic = sanitizeRecipeHtml(cheerio.load(htmlContent));

    return (
      <div className="LegacyRecipe md:p0 my4 flex flex-col items-center">
        {title && (
          <div className="LegacyRecipe__title border-bottom-dotted-black w100 py1_5 text-center flex justify-center font-700 graebenbach text-article-body-lg">
            {title}
          </div>
        )}
        <div
          className="LegacyRecipe__content text-article-body-sm font-400 graebenbach py1"
          dangerouslySetInnerHTML={{ __html: $.html() }}
        />
        <div className="LegacyRecipe__share-container border-top-dotted-black w100 py1_5 flex justify-center">
          <ShareButton className="mx1_5" />
          <Button
            ariaLabel={Language.t('Recipe.printButtonAriaLabel')}
            className="LegacyRecipe__print-button bg-color-transparent transition mx1_5"
            onClick={this.handlePrintButtonClick}
          >
            <span className="LegacyRecipe__text mr_25 graebenbach font-400">
              {Language.t('Recipe.print')}
            </span>
            <div className="inline-block ml_25 button-move-arrow graebenbach text-article-details-xs color-black">
              &rarr;&#xFE0E;
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default LegacyRecipe;
