import React from 'react';
import cx from 'classnames';
import { BlockContent } from 'components/BlockContent';

import { BlockRichText as IBlockRichText } from 'sharedTypes';

interface Props {
  block: IBlockRichText;
}

const BlockRichText: React.FC<Props> = ({ block }) => {
  const sansSerif = block.sansSerif;

  return (
    <div
      className={cx('BlockRichText mxauto', {
        'BlockRichText--serif itc-cushing font-300 py2': !sansSerif,
        'BlockRichText--sans-serif graebenbach font-400 text-section-body-md line-height-lg py1':
          sansSerif,
      })}
    >
      <BlockContent blocks={block.text} />
    </div>
  );
};

export default BlockRichText;
