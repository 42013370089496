import { ArticleBody } from 'sharedTypes';

type ArticleGroupType = (ArticleBody | ArticleBody[])[];

const groupArticleItems = (content: ArticleBody[]): ArticleGroupType => {
  let result: ArticleGroupType = [];
  let sublist: ArticleBody[] = [];

  content.forEach((item: any) => {
    if (item.level === 1 || item.listItem === 'number') {
      sublist.push(item);
    } else {
      if (sublist.length) {
        result.push(sublist);
        sublist = [];
      }
      result.push(item);
    }
  });

  if (sublist.length) {
    result.push(sublist);
  }

  return result;
};

export default groupArticleItems;
