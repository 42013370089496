import React, { useRef, useState } from 'react';
import cx from 'classnames';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';
import { Button } from 'components/base';
import { BlockRichText as RichText } from 'sharedTypes';
import Language from 'constants/Language';
import BlockRichText from 'components/BlockRichText';
import Divider from './Divider';

interface Props extends WithBreakpointsProps {
  children?: React.ReactNode;
  className?: string;
  title: string;
  body: RichText;
}

const Accordion: React.FC<Props> = ({ className, title, body }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div
        className={cx(
          'Accordion col-12 lg:col-10 xl-wide:col-8 mxauto text-article-body-md',
          className
        )}
      >
        <Button
          className="Accordion__button flex transition bradford font-600 relative"
          onClick={handleClick}
        >
          <span className="Accordion__title text-article-body-md">{title}</span>
          <span className="Accordion__icon absolute t0 r0">
            {isExpanded ? '-' : '+'}
          </span>
        </Button>
        <div
          className={cx('Accordion__content BlockContent', {
            'transition-open-search': isExpanded,
            'transition-close-search': !isExpanded,
          })}
        >
          <BlockRichText block={body} />
        </div>
      </div>
      <div className="Accordion__divider col-12 lg:col-10 xl-wide:col-8 mxauto">
        <Divider key="accordion" variant="dotted-rule" />
      </div>
    </>
  );
};

export default withBreakpoints<Props>(Accordion);
