import React, { useState, useRef, useEffect } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import cx from 'classnames';

import { Theme } from '../types';
import { Button } from '../components/base';
import Language from '../constants/Language';
import environmentIsProd from '../utils/environmentIsProd';
import { withUI } from '../providers/UIProvider';
import { useRouter } from 'next/router';

interface ContextProps {
  theme: Theme;
}
interface PassedProps {
  className: string;
  articleTitle?: string;
  articleBrief?: string;
  tweetText?: string;
  subtleTransition?: boolean;
  hidePrint?: boolean;
}

type Props = PassedProps & ContextProps;

interface State {
  shareIsActive: boolean;
  showCopySuccessIndicator: boolean;
}

const defaultProps = {
  className: '',
};

const ShareButton: React.FC<Props> = ({
  className,
  articleTitle,
  articleBrief,
  tweetText,
  subtleTransition,
  hidePrint,
  theme,
}) => {
  const router = useRouter();

  const [shareIsActive, setShareIsActive] = useState(false);
  const [showCopySuccessIndicator, setShowCopySuccessIndicator] =
    useState(false);

  const innerButtonsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (shareIsActive && innerButtonsRef.current) {
      const innerWidth = innerButtonsRef.current.offsetWidth + 35;
      document.documentElement.style.setProperty(
        '--inner-width',
        `${innerWidth}px`
      );
    }
  }, [shareIsActive]);

  const handleShareButtonClick = () => {
    setShareIsActive((prevShareIsActive) => !prevShareIsActive);
  };

  const copyUrlToClipboard = () => {
    if (!inputRef.current) {
      return null;
    }

    inputRef.current.select();
    document.execCommand('copy');

    setShowCopySuccessIndicator(true);

    setTimeout(() => {
      setShowCopySuccessIndicator(false);
    }, 1500);
  };

  const handlePrintButtonClick = () => {
    window.print();
  };

  const HOST = environmentIsProd()
    ? `https://www.tabletmag.com`
    : `https://staging.tabletmag.com`;
  const currentUrl = `${HOST}${router.asPath}`;

  return (
    <div
      className={cx(
        'ShareButton relative inline-flex block items-center justify-end',
        className,
        {
          'ShareButton--active': shareIsActive,
          'ShareButton--subtle-transition': subtleTransition,
        }
      )}
    >
      <Button
        ariaLabel={Language.t('ShareButton.shareButtonAriaLabel')}
        className={cx('ShareButton__button bg-color-transparent transition', {
          'ShareButton__button--active': shareIsActive,
        })}
        onClick={handleShareButtonClick}
        style={{ paddingRight: shareIsActive ? 'var(--inner-width)' : '0' }}
      >
        <span
          className={cx('ShareButton__text mr_25 graebenbach font-400', {
            'color-black': theme === Theme.Beige || theme === Theme.Default,
            'color-white-dark': theme === Theme.Black,
          })}
        >
          {Language.t('ShareButton.share')}
        </span>
        <div
          className={cx(
            'ShareButton__arrow-icon transition-short inline-block ml_25 button-move-arrow graebenbach font-400',
            {
              'color-black': theme !== Theme.Black,
              'color-off-white': theme === Theme.Black,
            }
          )}
        >
          &rarr;&#xFE0E;
        </div>
      </Button>
      <div
        ref={innerButtonsRef}
        className={cx('ShareButton__buttons absolute flex', {
          'events-none opacity-0 transition-short': !shareIsActive,
          'ShareButton__buttons--active events-all transition-long':
            shareIsActive,
        })}
      >
        <TwitterShareButton
          title={tweetText ? tweetText : articleTitle}
          url={currentUrl}
        >
          <div
            aria-label={Language.t('ShareButton.twitterShareButtonAriaLabel')}
            role="button"
            tabIndex={0}
            onClick={() => {}}
            className={cx(
              'ShareButton__text mr_75 md:mr1_25 graebenbach font-400 bg-color-transparent linked-border-bottom-sm-on-hover-red',
              {
                'color-black': theme === Theme.Beige || theme === Theme.Default,
                'color-white-dark': theme === Theme.Black,
              }
            )}
          >
            {Language.t('ShareButton.twitterShareButtonLabel')}
          </div>
        </TwitterShareButton>
        <FacebookShareButton url={currentUrl} quote={tweetText}>
          <div
            aria-label={Language.t('ShareButton.facebookShareButtonAriaLabel')}
            role="button"
            tabIndex={0}
            onClick={() => {}}
            className={cx(
              'ShareButton__text mr_75 md:mr1_25 graebenbach font-400 bg-color-transparent linked-border-bottom-sm-on-hover-red',
              {
                'color-black': theme === Theme.Beige || theme === Theme.Default,
                'color-white-dark': theme === Theme.Black,
              }
            )}
          >
            {Language.t('ShareButton.facebookShareButtonLabel')}
          </div>
        </FacebookShareButton>
        <EmailShareButton
          url={!articleBrief ? currentUrl : ''}
          subject={articleTitle ? articleTitle : ''}
          body={articleBrief ? articleBrief : ''}
        >
          <div
            aria-label={Language.t('ShareButton.emailShareButtonAriaLabel')}
            role="button"
            tabIndex={0}
            onClick={() => {}}
            className={cx(
              'ShareButton__text mr_75 md:mr1_25 graebenbach font-400 bg-color-transparent linked-border-bottom-sm-on-hover-red',
              {
                'color-black': theme === Theme.Beige || theme === Theme.Default,
                'color-white-dark': theme === Theme.Black,
              }
            )}
          >
            {Language.t('ShareButton.emailShareButtonLabel')}
          </div>
        </EmailShareButton>
        {hidePrint !== true ? (
          <Button
            ariaLabel={Language.t('ShareButton.printButtonAriaLabel')}
            label={Language.t('ShareButton.printButtonLabel')}
            className={cx(
              'ShareButton__text ShareButton__text--print graebenbach font-400 bg-color-transparent linked-border-bottom-sm-on-hover-red',
              {
                'color-black': theme === Theme.Beige || theme === Theme.Default,
                'color-white-dark': theme === Theme.Black,
              }
            )}
            onClick={handlePrintButtonClick}
          />
        ) : null}
        <Button
          ariaLabel={Language.t('ShareButton.linkButtonAriaLabel')}
          label={Language.t('ShareButton.linkButtonLabel')}
          className={cx(
            'ShareButton__text graebenbach font-400 bg-color-transparent linked-border-bottom-sm-on-hover-red',
            {
              'color-black': theme === Theme.Beige || theme === Theme.Default,
              'color-white-dark': theme === Theme.Black,
            }
          )}
          onClick={copyUrlToClipboard}
        />
        <span
          className={cx(
            'ShareButton__copied-text absolute r0 b0 t0  color-gray-darkest transition graebenbach font-400',
            {
              'ShareButton__copied-text--active opacity-1':
                showCopySuccessIndicator,
              'opacity-0 events-none': !showCopySuccessIndicator,
            }
          )}
        >
          {Language.t('ShareButton.copyLinkIndicator')}
        </span>
      </div>
      <input
        ref={inputRef}
        className="absolute events-none opacity-0"
        value={currentUrl}
        readOnly
      />
    </div>
  );
};

ShareButton.defaultProps = defaultProps;

export default withUI(ShareButton);
