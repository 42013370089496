import React, { useState } from 'react';
import cx from 'classnames';
import AppleIcon from 'public/assets/icons/audio-player/apple-icon.svg';
import { LinkedPodcastPlayer } from 'sharedTypes';
import { HexColors } from 'constants/Colors';
import { useTextOverflow } from 'src/hooks/useTextOverflow';

type SubsribeLinks = Pick<
  LinkedPodcastPlayer['linkedPodcastEpisode']['podcast'],
  'applePodcastLink' | 'spotifyPodcastLink' | 'rssLink'
>;
interface Props extends SubsribeLinks {
  isPodcastPlayer: boolean;
  className?: string;
  title?: string;
}

const SubscribeLink = ({ ...props }) => (
  <a target="_blank" rel="noopener noreferrer" {...props}>
    {props.children}
  </a>
);

const MainBox: React.FC<Props> = ({
  className,
  isPodcastPlayer,
  title,
  applePodcastLink,
  spotifyPodcastLink,
  rssLink,
}) => {
  const [linkText, setLinkText] = useState('RSS');
  const ref = React.useRef<HTMLDivElement>(null);
  const isOverflowing = useTextOverflow(ref);

  const copyUrlToClipboard = (url?: string) => {
    if (!url) {
      return;
    }
    navigator.clipboard.writeText(url);
    setLinkText('Copied!');

    setTimeout(() => {
      setLinkText('RSS');
    }, 1500);
  };
  return (
    <div ref={ref} className={cx(['AudioPlayer__MainBox', className])}>
      {isPodcastPlayer ? (
        <span className="subscribe-options">
          <span className="subscribe-text">Subscribe:</span>
          <SubscribeLink href={applePodcastLink} className="subscribe-option">
            <AppleIcon color={HexColors['black']} style={{ marginRight: 4 }} />
            Podcasts,
          </SubscribeLink>

          <SubscribeLink href={spotifyPodcastLink} className="subscribe-option">
            Spotify,
          </SubscribeLink>

          <span
            className="subscribe-option"
            onClick={() => copyUrlToClipboard(rssLink)}
          >
            {linkText}
          </span>
        </span>
      ) : (
        <span className={cx('plain-title', { 'slide-left': isOverflowing })}>
          {title}
          {isOverflowing && (
            <>
              <span className="marquee-spacing" />
              {title}
            </>
          )}
        </span>
      )}
      <span className="blur-end" />
    </div>
  );
};

export default MainBox;
