import React from 'react';
import { curlyQuotes } from 'utils/text';

export const Sup = (props: any) => {
  return <sup>{props.children}</sup>;
};

export const Sub = (props: any) => {
  return <sub>{props.children}</sub>;
};

// We use the "Code" type to allow the Tablet team to
// input in-page anchors! (Yes, it's a hack)
export const Code = (props: any) => {
  let link = '';
  if (Array.isArray(props.children) && props.children[0]) {
    link = props.children[0];
  }
  if (link && link.startsWith('#')) {
    link = link.slice(1);
  }
  return (
    <a id={link} href={`#${link}`} className="InPageAnchor">
      {curlyQuotes(link)}
    </a>
  );
};
