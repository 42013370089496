import React from 'react';
import cx from 'classnames';

import styles from './style.module.scss';

interface Props {
  containerClassName?: string;
  sliderClassName?: string;
  children?: React.ReactNode;
}

const LeftSlider: React.FC<Props> = ({
  children,
  containerClassName,
  sliderClassName,
}) => {
  return (
    <div className={cx(containerClassName, styles.leftSliderContainer)}>
      <div className={styles.borderDummy} />

      <div className={cx(sliderClassName, styles.slidingPart)}>{children}</div>
    </div>
  );
};

export default LeftSlider;
