import React from 'react';
import { Button } from '../components/base';
import Language from '../constants/Language';
import { Collection } from '../sharedTypes';
import cx from 'classnames';

type Props = Pick<Collection, 'title' | 'slug'> & {
  isArticleEditorsNotePresent: boolean;
  fullWidth: boolean;
  showEmbed?: boolean;
  position: string;
};

const CollectionEditorNote: React.FC<Props> = ({
  title,
  slug,
  isArticleEditorsNotePresent,
  fullWidth,
  showEmbed,
  position,
}) => {
  if (showEmbed !== undefined && showEmbed !== true) {
    return null;
  }

  return fullWidth ? (
    <div className="ArticleView__content">
      <div>
        <div
          className={cx('CollectionEditorNote col-12 lg:col-10 mxauto', {
            mb1_5: isArticleEditorsNotePresent,
            'CollectionEditorNote__full-width': fullWidth,
          })}
        >
          <div className={'CollectionEditorNote__container'}>
            <div className="graebenbach tag">collection</div>

            <div className="graebenbach CollectionEditorNote__text mb1_5">
              This article is part of <span className="italic">{title}</span>.
            </div>

            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', { title })}
              to={'/collections/' + slug}
              wrap={true}
            >
              <div className="TextIntroStoryTeaserWidget__continue-reading color-black graebenbach CollectionEditorNote__text">
                {Language.t('Global.seeFullCollection')} &rarr;&#xFE0E;
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={cx(
        'CollectionEditorNote col-10 lg:col-5 mxauto',
        {
          mb1_5: isArticleEditorsNotePresent,
          'CollectionEditorNote__half-width': !fullWidth,
          'CollectionEditorNote__left': position === 'left',
          'CollectionEditorNote__right': position === 'right',
          'CollectionEditorNote__center': position === 'center',
          'CollectionEditorNote__full-width': position === 'full-width',
        }
      )}
    >
      <div className={'CollectionEditorNote__container'}>
        <div className="graebenbach tag">collection</div>

        <div className="graebenbach CollectionEditorNote__text mb1_5">
          This article is part of <span className="italic">{title}</span>.
        </div>

        <Button
          ariaLabel={Language.t('Global.articleButtonAriaLabel', { title })}
          to={'/collections/' + slug}
          wrap={true}
        >
          <div className="TextIntroStoryTeaserWidget__continue-reading color-black graebenbach CollectionEditorNote__text">
            {Language.t('Global.seeFullCollection')} &rarr;&#xFE0E;
          </div>
        </Button>
      </div>
    </div>
  );
};

export default CollectionEditorNote;
