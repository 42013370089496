import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';

interface ProgressInfo {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

const initialProgressState: ProgressInfo = {
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  loadedSeconds: 0,
};

export const useAudioPlaying = () => {
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [bufferingInProgress, setBufferingInProgress] = useState(false);
  const [seekInProgress, setSeekInProgress] = useState(false);
  const [progressInfo, setProgressInfo] =
    useState<ProgressInfo>(initialProgressState);
  const [dragActiveValue, setDragActiveValue] = useState(
    progressInfo.playedSeconds
  );
  const [seekedValue, setSeekedValue] = useState(0);

  const processing = seekInProgress || bufferingInProgress;

  const progressIfSeekComplete = (progress: ProgressInfo) => {
    const seekedValueCloseToCurrentValue =
      progress.playedSeconds + 2 >= seekedValue &&
      progress.playedSeconds - 2 <= seekedValue;

    if (seekedValueCloseToCurrentValue) {
      setProgressInfo(progress);
      setSeekInProgress(false);
    }
  };

  const handleProgress = (progress: ProgressInfo) => {
    if (!processing) {
      setProgressInfo(progress);
      return;
    }

    progressIfSeekComplete(progress);
  };

  return {
    playerRef,
    isPlaying,
    dragActiveValue: processing ? dragActiveValue : progressInfo.playedSeconds,
    setDragActiveValue,
    handleProgress,
    togglePlaying: () => setIsPlaying((v) => !v),
    onBuffer: () => setBufferingInProgress(true),
    onBufferEnd: () => setBufferingInProgress(false),
    onBeforeChange: () => setSeekInProgress(true),
    onAfterChange: (v: number) => {
      playerRef.current?.seekTo(v);
      setSeekedValue(v);
    },
  };
};
