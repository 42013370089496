import React, { Component, createRef, RefObject } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { Img } from 'components/base';
import withBreakpoints, {
  Breakpoints,
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';
import getCarouselImgInfo from 'utils/getCarouselImgInfo';
import { onWindowResize } from 'utils/onWindowResize';
import { Position, Image } from 'sharedTypes';
import { ArticleImgCarouselImage, Theme } from 'types';
import { curlyQuotes } from 'utils/text';
import { UIConsumer } from 'providers/UIProvider';
import { BlockContent } from './BlockContent';

const Flickity =
  typeof window === 'undefined' ? () => null : require('flickity');

interface StoreProps {
  theme?: Theme;
}

interface PassProps {
  images: Image[];
  position: Position;
}

type Props = StoreProps & PassProps & WithBreakpointsProps;

interface State {
  images: ArticleImgCarouselImage[];
  imageMaxHeight: number;
  activeIndex: number;
  captionHeight: number;
  pageDotWidth: number;
  carouselWidth: number;
  loadedImg: boolean[];
}

class InsetImgSlideshow extends Component<Props, State> {
  wrapper: Element | null = null;
  flickity: typeof Flickity | null = null;
  captionContainer: RefObject<HTMLDivElement> = createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      images: [],
      imageMaxHeight: 0,
      activeIndex: 0,
      captionHeight: 0,
      pageDotWidth: 0,
      carouselWidth: 0,
      loadedImg: [],
    };
  }

  removeResizeListener = () => {};

  handleResize = () => {
    if (this.flickity) {
      this.flickity.destroy();
    }

    this.setCarouselImageInfo();
    this.initializeCarousel();
    this.setCaptionHeight();
    this.setPageDotWidth();
  };

  componentDidMount() {
    this.handleResize();
    this.removeResizeListener = onWindowResize(this.handleResize);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { images, currentBreakpoint } = this.props;
    const { loadedImg } = this.state;

    if (prevProps.currentBreakpoint !== currentBreakpoint) {
      this.handleResize();
    }

    if (
      images.length <= loadedImg.length &&
      prevState.loadedImg.length !== loadedImg.length
    ) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  initializeCarousel = () => {
    if (this.wrapper) {
      this.flickity = new Flickity(this.wrapper, {
        initialIndex: 0,
        wrapAround: false,
        draggable: true,
        freeScroll: false,
        prevNextButtons: true,
        accessibility: true,
        pageDots: true,
        cellAlign: this.getCellAlign(),
        resize: true,
        on: {
          ready: () => {
            setTimeout(() => {
              this.flickity && this.flickity.resize();
            }, 0);
          },
          change: (index?: number) => {
            if (typeof index === 'number') {
              this.setState({ activeIndex: index });
            }
          },
        },
      });
    }
  };

  getCellAlign = () => {
    const { currentBreakpoint, position } = this.props;

    if (position === Position.LEFT || position === Position.RIGHT) {
      return Position.LEFT;
    }

    if (
      currentBreakpoint === Breakpoints.SMALL.label ||
      currentBreakpoint === Breakpoints.EXTRA_SMALL.label
    ) {
      return Position.LEFT;
    }

    return Position.CENTER;
  };

  setCarouselImageInfo = () => {
    if (this.wrapper) {
      const carouselWidth = get(this, 'wrapper.clientWidth', 0) as number;

      const carouselImageInfo = getCarouselImgInfo({
        carouselWidth,
        images: this.props.images,
      });

      this.setState({
        carouselWidth,
        images: carouselImageInfo.images,
        imageMaxHeight: carouselImageInfo.imageMaxHeight,
      });
    }
  };

  setCaptionHeight = () => {
    const container = this?.captionContainer.current;
    const captionContainerChildren = get(
      this.captionContainer.current,
      'children'
    );
    const captions = container && captionContainerChildren;

    if (!captions) {
      return;
    }

    const tallestCaptionHeight = Array.from(captions).reduce<number>(
      (tallestCaptionHeight: number, caption: Element) => {
        const captionHeight = get(caption, 'clientHeight', 0);

        if (captionHeight > tallestCaptionHeight) {
          return captionHeight;
        }

        return tallestCaptionHeight;
      },
      0
    );

    this.setState({ captionHeight: tallestCaptionHeight });
  };

  setPageDotWidth = () => {
    const pageDotWidth = get(
      document.getElementsByClassName('flickity-page-dots'),
      '[0].clientWidth',
      0
    );
    this.setState({ pageDotWidth });
  };

  handleImgLoad = () => {
    this.setState((state) => ({ loadedImg: state.loadedImg.concat([true]) }));
  };

  render() {
    const { position, theme, currentBreakpoint } = this.props;
    const {
      images,
      imageMaxHeight,
      activeIndex,
      captionHeight,
      pageDotWidth,
      carouselWidth,
    } = this.state;

    const breakpointIsMdDown =
      currentBreakpoint === Breakpoints.SMALL.label ||
      currentBreakpoint === Breakpoints.EXTRA_SMALL.label;

    return (
      <div
        className={cx(
          `InsetImgSlideshow InsetImgSlideshow--${theme} InsetImgSlideshow--${position} overflow-hidden`,
          {
            'border-bottom-black': theme === Theme.Default,
            'border-bottom-gray-darker': theme === Theme.Black,
            [`InsetImgSlideshow--${position}-black`]: theme === Theme.Black,
            'InsetImgSlideshow--multi-image': images && images.length > 1,
          }
        )}
      >
        <div className="relative">
          <div
            className="InsetImgSlideshow__carousel-container relative w100"
            ref={(node) => (this.wrapper = node)}
          >
            {images &&
              images.map((image: ArticleImgCarouselImage, index) => {
                const { carouselimage, width, height } = image;

                return (
                  <div
                    key={`${carouselimage.src}-${index}`}
                    style={{
                      height: `${imageMaxHeight}px`,
                      width: breakpointIsMdDown ? '100%' : `${width}px`,
                    }}
                    className="InsetImgSlideshow__image-container mx_5 flex items-start mb1"
                  >
                    <Img
                      style={{
                        width: breakpointIsMdDown ? '100%' : `${width}px`,
                        height: `${height}px`,
                        minHeight: `${height}px`,
                        minWidth: breakpointIsMdDown ? '100%' : `${width}px`,
                      }}
                      key={carouselimage.src}
                      className="InsetImgSlideshow__image fit-contain"
                      alt={
                        carouselimage.alt
                          ? carouselimage.alt
                          : get(image, 'caption', '')
                      }
                      onImgLoad={this.handleImgLoad}
                      sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1080px"
                      src={sanityImgUtil(carouselimage, 1080)}
                      dimensions={carouselimage.metadata?.dimensions}
                      srcSet={sanityImgSrcSetUtil(
                        carouselimage,
                        768,
                        1080,
                        1080
                      )}
                    />
                  </div>
                );
              })}
          </div>
          <div
            ref={this.captionContainer as RefObject<HTMLDivElement>}
            style={{ height: `${captionHeight}px` }}
          >
            {images &&
              images.map((image: ArticleImgCarouselImage, i: number) => {
                const { carouselimage } = image;
                return (
                  <div
                    key={`caption-${carouselimage.src}-${i}`}
                    style={{ width: `${carouselWidth - pageDotWidth - 30}px` }}
                    className={cx(
                      'InsetImgSlideshow__caption-container absolute transition',
                      {
                        'opacity-0 events-none': activeIndex !== i,
                        'opacity-1 events-all': activeIndex === i,
                      }
                    )}
                  >
                    {carouselimage.caption && (
                      <span
                        className={cx(
                          'InsetImgSlideshow__caption text-article-details-xs font-400 graebenbach',
                          {
                            'color-gray-darkest': theme === Theme.Default,
                            'color-gray-lighter': theme === Theme.Black,
                          }
                        )}
                      >
                        {curlyQuotes(carouselimage.caption)}
                      </span>
                    )}
                    {carouselimage.rteCredit ? (
                      <span className="ImageCreditLink inline-block ml_5 color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md transition-shorter">
                        <BlockContent blocks={carouselimage.rteCredit} />
                      </span>
                    ) : carouselimage.credit ? (
                      <span
                        className={cx(
                          'InsetImgSlideshow__credit ml_5 color-gray text-article-details-xxs font-400 uppercase graebenbach'
                        )}
                      >
                        {curlyQuotes(carouselimage.credit)}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default withBreakpoints<Props>((props) => (
  <UIConsumer>
    {(value) => <InsetImgSlideshow {...props} {...value} />}
  </UIConsumer>
));
