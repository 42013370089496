import RCSlider, { SliderProps as RCSliderProps } from 'rc-slider';
import cx from 'classnames';
import 'rc-slider/assets/index.css';
import { HexColors } from 'constants/Colors';

const Slider: React.FC<RCSliderProps> = ({ className, ...rest }) => (
  <RCSlider
    className={cx('slider', className)}
    min={0}
    max={1}
    step={0.01}
    defaultValue={0.5}
    trackStyle={{
      backgroundColor: HexColors['red'],
      height: 4,
      borderRadius: 0,
    }}
    handleStyle={{
      borderColor: HexColors['off-white'],
      marginTop: 0,
      backgroundColor: HexColors['red'],
      boxShadow: 'none',
    }}
    railStyle={{
      backgroundColor: 'var(--podcast-ruler-color)',
      height: 4,
      borderRadius: 0,
    }}
    {...rest}
  />
);

export default Slider;
