import React from 'react';
import { timestampFormat } from 'src/hooks';

const Timestamp: React.FC<{ time: string }> = ({ time }) => {
  const totalTime = time.split('/')[1];

  return (
    <div className="AudioPlayer__TimestampContainer">
      <span className="AudioPlayer__TimestampContainer__Text vertical-align-top absolute">
        {time}
      </span>
      <span className="AudioPlayer__TimestampContainer__Text vertical-align-top widest-timestamp">
        {timestampFormat(totalTime, totalTime)}
      </span>
    </div>
  );
};

export default Timestamp;
