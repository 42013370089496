import React, { useEffect, useState } from 'react';
import { Link } from 'components/Link';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import cx from 'classnames';
import get from 'lodash/get';
import { RouteMap } from 'constants/RouteMap';
import Language from 'constants/Language';
import { Img, Button } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import SectionButton from 'components/SectionButton';
import {
  ArticleLink,
  Image,
  PodcastEpisodeLink,
  SectionLink,
} from 'sharedTypes';
import generatePodcastEpisodeUrl from 'utils/generatePodcastEpisodeUrl';
import { curlyQuotes } from 'utils/text';
import generateRelatedArticlesUrl from 'utils/generateRelatedArticlesUrl';
import ApiClient from 'lib/ApiClient';

interface Props {
  header?: string;
  variant: 'small' | 'large' | 'with-image';
  relatedArticles: (ArticleLink | PodcastEpisodeLink)[];
}

const RelatedArticles: React.FC<Props> = ({
  header,
  variant,
  relatedArticles,
}) => {
  const customHeader = header || Language.t('RelatedArticles.header');
  const [convertedRelatedArticles, setConvertedRelatedArticles] = useState<
    (ArticleLink | PodcastEpisodeLink)[]
  >([]);

  useEffect(() => {
    const getRelatedArticles = () =>
      Promise.all(
        relatedArticles.map((article: ArticleLink | PodcastEpisodeLink) => {
          const id = get(article, 'id');

          if (article.podcast && id) {
            const podcastEp = ApiClient.fetchPodcastEpisodeByRef(id);
            return podcastEp;
          } else {
            return article;
          }
        })
      ).then((val) => {
        setConvertedRelatedArticles(val);
      });

    getRelatedArticles();
  }, [relatedArticles]);

  return (
    <div
      className={`RelatedArticles RelatedArticles--${variant} w100 lg:ml1_5 lg:pl1_5 mb1_5 lg:right`}
    >
      <div className="RelatedArticles itc-cushing text-section-details-sm font-800 mt1_5 lg:mt0 text-left lg:text-center uppercase">
        <div className="RelatedArticles__header border-bottom-dotted-black md:pb1_5">
          {customHeader}
        </div>
      </div>

      <div
        className="RelatedArticles__articles-container
      flex flex-row lg:flex-col"
      >
        {convertedRelatedArticles &&
          convertedRelatedArticles.map(
            (article: ArticleLink | PodcastEpisodeLink) => {
              const image: Image =
                get(article, 'heroImage') ??
                (get(article, 'image') as unknown as Image);
              const section: SectionLink = get(
                article,
                'section'
              ) as unknown as SectionLink;
              const slug = get(article, 'slug', '');
              const brief = get(article, 'brief');
              const dek = get(article, 'dek', '');
              const authors = get(article, 'authors');
              const podcastSection = get(article, 'podcast.slug');

              const isPodcast = !section && podcastSection;

              return (
                <div
                  key={article.slug}
                  className="RelatedArticles__article-container border-bottom-dotted-black flex flex-col col-4 lg:col-12 lg:items-center text-left lg:text-center lg:pt_5 pb3"
                >
                  {image &&
                    variant === 'with-image' &&
                    (isPodcast ? (
                      <Button
                        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                          title: article.title,
                        })}
                        containerClassName="RelatedArticles__image relative lg:mt1"
                        className="none lg:block w100"
                        to={generatePodcastEpisodeUrl(podcastSection, slug)}
                        wrap={true}
                      >
                        <Img
                          className="w100 radius-sm grayscale-img w100"
                          alt={image.credit || image.alt || ''}
                          sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540px, 1080px"
                          src={sanityImgUtil(image, 1080)}
                          srcSet={sanityImgSrcSetUtil(image, 768, 540, 1080)}
                          dimensions={
                            image.metadata && image.metadata.dimensions
                          }
                          crop={image.crop}
                        />
                        <Img
                          className="w100 radius-sm color-img absolute t0 b0 r0 l0 w100"
                          alt={image.credit || image.alt || ''}
                          sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540px, 1080px"
                          src={sanityImgUtil(image, 1080)}
                          dimensions={image.metadata?.dimensions}
                          srcSet={sanityImgSrcSetUtil(image, 768, 540, 1080)}
                        />
                      </Button>
                    ) : (
                      <Button
                        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                          title: article.title,
                        })}
                        containerClassName="RelatedArticles__image relative lg:mt1"
                        className="none lg:block w100"
                        to={generateRelatedArticlesUrl(section.slug, slug)}
                        wrap={true}
                      >
                        <Img
                          className="w100 radius-sm grayscale-img w100"
                          alt={image.credit || image.alt || ''}
                          sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540px, 1080px"
                          src={sanityImgUtil(image, 1080)}
                          srcSet={sanityImgSrcSetUtil(image, 768, 540, 1080)}
                          dimensions={
                            image.metadata && image.metadata.dimensions
                          }
                          crop={image.crop}
                        />
                        <Img
                          className="w100 radius-sm color-img absolute t0 b0 r0 l0 w100"
                          alt={image.credit || image.alt || ''}
                          sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540px, 1080px"
                          src={sanityImgUtil(image, 1080)}
                          dimensions={image.metadata?.dimensions}
                          srcSet={sanityImgSrcSetUtil(image, 768, 540, 1080)}
                        />
                      </Button>
                    ))}
                  {section ? (
                    <SectionButton
                      section={section}
                      className="py_75 md:pb1 md:pt_75"
                    />
                  ) : (
                    <div className="RelatedArticles__hidden-section py_75 md:pb1 md:pt_75 lg:pb0 lg:pt0">
                      Podcast
                    </div>
                  )}
                  {isPodcast && (
                    <Button
                      ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                        title: article.title,
                      })}
                      to={generatePodcastEpisodeUrl(podcastSection, slug)}
                      wrap={true}
                    >
                      <div
                        className={cx(
                          'RelatedArticles__article-title text-article-details-lg text-decoration-none itc-cushing font-600 color-black pb_5'
                        )}
                      >
                        <span className="linked-border-bottom-sm-on-hover-red">
                          {curlyQuotes(article.title)}
                        </span>
                      </div>
                    </Button>
                  )}
                  {section && (
                    <Button
                      ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                        title: article.title,
                      })}
                      to={generateRelatedArticlesUrl(section.slug, slug)}
                      wrap={true}
                    >
                      <div
                        className={cx(
                          'RelatedArticles__article-title text-article-details-lg text-decoration-none itc-cushing font-600 color-black pb_5',
                          {
                            mt1_5: !section,
                          }
                        )}
                      >
                        <span className="linked-border-bottom-sm-on-hover-red">
                          {curlyQuotes(article.title)}
                        </span>
                      </div>
                    </Button>
                  )}
                  {(brief || dek) && (
                    <Button
                      ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                        title: article.title,
                      })}
                      className={cx({
                        none: !(
                          variant === 'with-image' || variant === 'large'
                        ),
                      })}
                      to={
                        isPodcast
                          ? generatePodcastEpisodeUrl(podcastSection, slug)
                          : generateRelatedArticlesUrl(section.slug, slug)
                      }
                      wrap={true}
                    >
                      <div className="RelatedArticles__brief color-gray-darker graebenbach font-400 pb1_5 lg:mx1">
                        {curlyQuotes(brief ?? dek)}
                      </div>
                    </Button>
                  )}
                  {authors && section && <AuthorButtons authors={authors} />}
                  {isPodcast && (
                    <span className="text-section-details-xs itc-cushing font-800 line-height-xs color-black uppercase">
                      {Language.t('Global.byline')}
                      <Link
                        to={`${RouteMap.PODCAST.base}/${podcastSection}`}
                        className="RelatedArticles__podcast-link ml_25 color-black transition-shorter"
                      >
                        {curlyQuotes(podcastSection)}
                      </Link>
                    </span>
                  )}
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

export default RelatedArticles;
