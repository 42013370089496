import { createSelector } from 'reselect';

import get from 'lodash/get';

import { Image } from 'sharedTypes';
import { ArticleImgCarouselImage } from 'types';

export interface CarouselInfo {
  carouselWidth: number;
  images: Image[];
}

export interface SanitizedCarouselInfo {
  images: ArticleImgCarouselImage[];
  carouselWidth: number;
  imageMaxHeight: number;
}

export default createSelector(
  (carouselInfo: CarouselInfo): number => carouselInfo.carouselWidth,
  (carouselInfo: CarouselInfo): Image[] => carouselInfo.images,
  (carouselWidth: number, carouselImages: Image[]): SanitizedCarouselInfo => {
    let imageMaxHeight = 0;
    let carouselImgHeight = 0;

    const images = carouselImages.map((image: Image, i) => {
      const imageWidth =
        get(image, 'metadata.dimensions.width', 0) *
        (1 - get(image, 'crop.left', 0) - get(image, 'crop.right', 0));
      const imageHeight =
        get(image, 'metadata.dimensions.height', 0) *
        (1 - get(image, 'crop.bottom', 0) - get(image, 'crop.top', 0));

      let height = 0;
      let width = carouselWidth;

      if (i === 0) {
        height = width / (imageWidth / imageHeight);
        carouselImgHeight = height;
        imageMaxHeight = height;
      } else {
        height = carouselImgHeight;
        width = (imageWidth / imageHeight) * height;

        if (width > carouselWidth) {
          width = carouselWidth;
          height = width / (imageWidth / imageHeight);
        }
      }

      return {
        height,
        width,
        carouselimage: image,
      };
    });

    return { images, carouselWidth, imageMaxHeight };
  }
);
