import React, { useState } from 'react';
import cx from 'classnames';
import { SliderProps, Handle } from 'rc-slider';
import Slider from 'components/base/Slider';
import { formatSeconds } from 'utils/dateRoundedToDat';

import styles from './style.module.scss';

interface Props extends SliderProps {}

const handle = (props: any) => {
  const { value, dragging, index, ...rest } = props;

  return (
    <Handle key={index} value={value} {...rest}>
      <div className={cx(['audio-slider-tooltip', styles.sliderText])}>
        {formatSeconds(value)}
      </div>
    </Handle>
  );
};

const AudioSlider: React.FC<Props> = ({ className, ...rest }) => {
  return <Slider {...rest} className={cx([className])} handle={handle} />;
};

export default AudioSlider;
