import React from 'react';
import Button from './base/Button';
import { PortableText } from 'sharedTypes';
import { BlockContent } from './BlockContent';

interface Props {
  headline: string;
  subheadline: PortableText;
  ctaText: string;
  ctaLink: string;
  useDarkModeColorScheme?: boolean;
}

const DonateButton: React.FC<Props> = ({
  headline,
  subheadline,
  ctaText,
  ctaLink,
  useDarkModeColorScheme,
}) => {
  return (
    <div
      className={`DonateButton__container w100 flex flex-col items-center justify-center col-12 lg:col-10 mxauto ${
        useDarkModeColorScheme ? 'bg-color-black DonateButton--border-red' : 'bg-color-beige'
      }`}
    >
      <div className={'DonateButton__headline-container w100 flex'}>
        <span
          className={`DonateButton__headline font-400 itc-cushing mxauto ${
            useDarkModeColorScheme ? 'color-beige' : 'color-black'
          }`}
        >
          {headline}
        </span>
      </div>
      <div
        className={`DonateButton__subheadline-container w100 graebenbach' ${
          useDarkModeColorScheme ? 'color-beige' : 'color-black'
        }`}
      >
        <BlockContent blocks={subheadline} />
      </div>
      <Button
        ariaLabel={`ArticleContent__donate-button`}
        onClick={() => window.open(`${ctaLink}`)}
        className={
          'DonateButton__donation-amount-button flex items-center justify-center bg-color-red color-off-white'
        }
      >
        <span className="graebenbach font-700">{ctaText}</span>
      </Button>
    </div>
  );
};

export default DonateButton;
