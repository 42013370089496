import React from 'react';

interface Props {
  variant: 'dotted-rule' | 'space' | 'rule' | 'short-rule';
}

const Divider: React.FC<Props> = ({ variant }) => {
  return (
    <div className="col-12 lg:col-10 xl-wide:col-8 mxauto">
      <div className={`Divider Divider--${variant} overflow-hidden`} />
    </div>
  );
};

export default Divider;
