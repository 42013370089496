import slugify from 'utils/slugify';

import { Article, ArticleRecipeContent } from 'sharedTypes';

const generatePrintRecipeUrl = (
  recipe: ArticleRecipeContent,
  article?: Article
) => {
  if (!article) return '';

  return `/articles/${article.slug.split('/').pop()}/print/${slugify(
    recipe.title
  )}`;
};

export default generatePrintRecipeUrl;
