import {
  ArticleHeroType,
  ArticleLink,
  Collection,
  CollectionsPage,
} from '../sharedTypes';

export const formatFeaturedCollections = (
  collectionPage: CollectionsPage
): Collection[] =>
  [
    ...Object.keys(collectionPage)
      .map((collection) => {
        if (collection.includes('collection')) {
          return collectionPage[collection as keyof typeof collectionPage];
        }
      })
      .filter((value) => !!value),
  ] as Collection[];

export const shouldCollectionsEditorNoteFullWidth = (
  heroType: ArticleHeroType
) =>
  ['full-width-header-with-image', 'no-image-centered-text'].includes(heroType);

export const formatCollectionsPage = (
  featuredCollections: Collection[],
  allCollections: Collection[]
) => [
  ...featuredCollections,
  ...allCollections.filter(
    (collection: Collection) =>
      !featuredCollections.some(
        (featuredCollections) => featuredCollections.id === collection.id
      )
  ),
];

export const formatCollectionPage = (
  featuredArticles: ArticleLink[],
  allArticles: ArticleLink[]
) => ({
  featuredArticles,
  allArticles: allArticles.filter(
    (article: ArticleLink) =>
      !featuredArticles.some(
        (featuredArticles) => featuredArticles.id === article.id
      )
  ),
});
