import { useState, useEffect } from 'react';

export const useTextOverflow = (ref: React.RefObject<HTMLDivElement>) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const sanitizedScrollWidth = ref.current?.scrollWidth || 0;
      const sanitizedClientWidth = ref.current?.clientWidth || 0;

      const isAnimatingSpanPresent = document.querySelector('.marquee-spacing');

      const realScrollWidth = isAnimatingSpanPresent
        ? sanitizedScrollWidth / 2
        : sanitizedScrollWidth;

      if (sanitizedClientWidth < realScrollWidth) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isOverflowing;
};
