import React from 'react';
import Back15 from 'public/assets/icons/audio-player/back-15.svg';
import PlayIcon from 'public/assets/icons/audio-player/play.svg';
import PauseIcon from 'public/assets/icons/audio-player/pause.svg';
import Forward15 from 'public/assets/icons/audio-player/forward-15.svg';
import { HexColors } from 'constants/Colors';
import ReactPlayer from 'react-player';

interface ControlsProps {
  playerRef: React.RefObject<ReactPlayer>;
  isPlaying: boolean;
  togglePlaying: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  playerRef,
  isPlaying,
  togglePlaying,
}) => {
  const forwardSeek = (timeInSeconds: number) => {
    const currentElapsedTimeInSeconds = playerRef.current?.getCurrentTime();

    if (currentElapsedTimeInSeconds) {
      playerRef.current?.seekTo(
        currentElapsedTimeInSeconds + timeInSeconds,
        'seconds'
      );
    }
  };

  return (
    <div className="AudioPlayer__ControlsContainer">
      <div className="AudioPlayer__ControlsContainer__InnerContainer">
        <Back15 color={HexColors['black']} onClick={() => forwardSeek(-15)} />
        {isPlaying ? (
          <PauseIcon color={HexColors['black']} onClick={togglePlaying} />
        ) : (
          <PlayIcon color={HexColors['black']} onClick={togglePlaying} />
        )}

        <Forward15 color={HexColors['black']} onClick={() => forwardSeek(15)} />
      </div>
    </div>
  );
};

export default Controls;
