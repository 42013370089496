import React, { useState } from 'react';
import ShareIcon from 'public/assets/icons/audio-player/share-arrow.svg';
import LeftSlider from './LeftSlider';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import { useWindowWidth } from 'src/hooks';
import { HexColors } from 'constants/Colors';

interface Props {
  link: string;
}

const ShareAudio: React.FC<Props> = ({ link }) => {
  const [linkText, setLinkText] = useState('Link');
  const windowWidth = useWindowWidth();

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(link);
    setLinkText('Copied!');

    setTimeout(() => {
      setLinkText('Link');
    }, 1500);
  };

  return (
    <div
      className="AudioPlayer__ShareContainer"
      onClick={() => {
        if (windowWidth < 768) {
          navigator?.share({ url: link });
        }
      }}
    >
      <LeftSlider sliderClassName="AudioPlayer__ShareContainer__SlidingPart">
        <div className="share-opener">
          <ShareIcon color={HexColors['black']} />
        </div>

        <ul className="share-options">
          <li className="share-option linked-border-bottom-sm-on-hover-red">
            <FacebookShareButton url={link}>Facebook</FacebookShareButton>
          </li>
          <li className="share-option linked-border-bottom-sm-on-hover-red">
            <TwitterShareButton url={link}>Twitter</TwitterShareButton>
          </li>
          <li className="share-option linked-border-bottom-sm-on-hover-red">
            <EmailShareButton url={link}>Email</EmailShareButton>
          </li>
          <li
            className="share-option linked-border-bottom-sm-on-hover-red"
            onClick={copyUrlToClipboard}
          >
            {linkText}
          </li>
        </ul>
      </LeftSlider>
    </div>
  );
};

export default ShareAudio;
