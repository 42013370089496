import React from 'react';
import VolumeIcon from 'public/assets/icons/audio-player/volume.svg';
import LeftSlider from './LeftSlider';
import { HexColors } from 'constants/Colors';

interface Props {
  children?: React.ReactNode;
}

const Volume: React.FC<Props> = ({ children }) => {
  return (
    <div className="AudioPlayer__VolumeContainer">
      <LeftSlider sliderClassName="AudioPlayer__VolumeContainer__SlidingPart">
        <div className="volume-opener">
          <VolumeIcon color={HexColors['black']} />
        </div>

        {children}
      </LeftSlider>
    </div>
  );
};

export default Volume;
