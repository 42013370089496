import React from 'react';
import { BlockContent } from '../components/BlockContent';
import cx from 'classnames';

import Ad from './Ad';
import InsetImg from './InsetImg';
import InsetImgSlideshow from './InsetImgSlideshow';
import Recipe from './Recipe';
import EmbedMedia from './EmbedMedia';
import PullQuote from './PullQuote';
import RelatedArticles from './RelatedArticles';
import Divider from './Divider';
import LegacyRecipe from './LegacyRecipe';
import { Sub, Sup, Code } from './serializers';

import {
  Article,
  ArticleBody,
  AuthorLink,
  ArticleLink,
  PodcastEpisode as IPodcastEpisode,
  FeatureArticlePage,
  ArticleHeroType,
} from '../sharedTypes';
import AudioPlayer from './AudioPlayer';
import { sanitizePodcastAudio } from '../sanitizers/sanitizePodcastEpisodesResponse';
import RelatedArticlesByCollection from './relatedArticlesByCollection';
import { ColumnsCarousel } from './ColumnsCarousel';
import NewsletterSignup from './NewsletterSignup';
import DonateButton from './DonateButton';
import CollectionEditorNote from './CollectionEditorNote';

import groupArticleItems from '../utils/groupArticleItems';
import { shouldCollectionsEditorNoteFullWidth } from '../utils/collections';
import Accordion from './Accordion';
import ShareButtonEmbed from './ShareButtonEmbed';

interface Props {
  article?: Article | FeatureArticlePage;
  podcastEpisode?: IPodcastEpisode;
  contents: ArticleBody[];
  className?: string;
  textBlockClassName?: string;
  isPodcast?: boolean;
  openCarouselOverlay?: (activeImageId: string) => void;
  enableDropCaps?: boolean;
  isTwoColHeroType?: boolean;
  topContent?: React.ReactNode;
  insertChildrenAt?: number;
  articles?: ArticleLink[];
  author?: AuthorLink;
  children?: React.ReactNode;
  isFeatureArticleContent?: boolean;
  featureArticleBackgroundColor?: string;
}

const ArticleContent: React.FC<Props> = ({
  contents,
  className,
  topContent,
  textBlockClassName,
  article,
  podcastEpisode,
  isPodcast,
  openCarouselOverlay,
  enableDropCaps,
  isTwoColHeroType,
  insertChildrenAt,
  children,
  articles,
  isFeatureArticleContent,
  featureArticleBackgroundColor,
}) => {
  if (!Array.isArray(contents)) {
    return null;
  }

  const Block = (
    content: ArticleBody | ArticleBody[],
    i: number,
    article?: Article | FeatureArticlePage
  ) => {
    if (Array.isArray(content)) {
      return (
        <div
          className={cx(textBlockClassName, {
            'BlockContent col-12 lg:col-10 xl-wide:col-8 mxauto': !isPodcast,
          })}
        >
          <BlockContent
            serializers={{
              marks: {
                sup: Sup,
                sub: Sub,
                code: Code,
              },
              block: {
                'blockquote-italic': ({ children }: any) => (
                  <blockquote className="Blockquote Blockquote--italic">
                    {children}
                  </blockquote>
                ),
              },
            }}
            blocks={content}
          />
        </div>
      );
    }

    switch (content.type) {
      case 'accordion':
        return (
          <Accordion
            key={content.id}
            title={content.headerText}
            body={content.richTextBlock}
          />
        );
      case 'column':
        return (
          <div className="ColumnsCarousel__embed">
            <ColumnsCarousel
              key={content.column.title}
              column={content.column}
            />
          </div>
        );
      case 'block':
        return (
          <div
            key={content.id}
            className={cx(textBlockClassName, {
              'BlockContent col-12 lg:col-10 xl-wide:col-8 mxauto': !isPodcast,
              //for all 1st paragraph dropcaps except 2 col article hero types
              'text-article-dropcaps':
                ((!isPodcast && i === 0) || content.style === 'dropcap') &&
                article &&
                !isTwoColHeroType,
              //for all non 1st paragraph dropcaps
              'text-article-dropcaps-body-view':
                i !== 0 &&
                article &&
                content.style === 'dropcap' &&
                enableDropCaps,
            })}
          >
            <BlockContent
              serializers={{
                marks: {
                  sup: Sup,
                  sub: Sub,
                  code: Code,
                },
                block: {
                  'blockquote-italic': ({ children }: any) => (
                    <blockquote className="Blockquote Blockquote--italic">
                      {children}
                    </blockquote>
                  ),
                },
              }}
              blocks={[content]}
            />
          </div>
        );
      case 'collectionEmbed':
        return (
          content.showCollection && (
            <CollectionEditorNote
              isArticleEditorsNotePresent={false}
              title={
                article?._type === 'article' ? article?.collection?.title : ''
              }
              slug={
                article?._type === 'article' ? article?.collection?.slug : ''
              }
              fullWidth={shouldCollectionsEditorNoteFullWidth(
                article?._type === 'article'
                  ? article?.hero?.heroType
                  : ArticleHeroType.FULL_WIDTH_IMAGE_WITH_HALF_WIDTH_HEADER
              )}
              showEmbed={content.showCollection}
              position={content.position}
              key={content.id}
            />
          )
        );
      case 'insetImageSlideshow':
        return (
          <InsetImgSlideshow
            key={content.id}
            position={content.position}
            images={content.slideshowImages}
          />
        );
      case 'insetImage':
        return (
          <InsetImg
            key={content.id}
            position={content.position}
            image={content.image}
            rules={content.rules}
            url={content.url}
            openCarouselOverlay={openCarouselOverlay}
          />
        );
      case 'pullQuote':
        return (
          <PullQuote
            key={content.id}
            article={article as Article}
            text={content.text}
            variant={content.position}
          />
        );
      case 'audioPlayer':
      case 'podcastPlayer':
        return (
          <AudioPlayer
            key={content.id}
            content={sanitizePodcastAudio(content, podcastEpisode)}
            className={cx({
              'col-12 lg:col-10 xl-wide:col-8 mxauto': !isPodcast,
              'player-at-top': i === 0,
            })}
            isFeatureArticleContent={isFeatureArticleContent}
            featureArticleBackgroundColor={featureArticleBackgroundColor}
          />
        );
      case 'embedBlock':
        return (
          <EmbedMedia
            key={content.id}
            src={content.url}
            html={content.html}
            title={content.title}
            size={content.size}
            caption={content.caption}
            attribution={content.attribution}
          />
        );
      case 'relatedStories':
        return (
          <RelatedArticles
            key={content.id}
            header={content.header}
            variant={content.variant}
            relatedArticles={content.articles}
          />
        );
      case 'relatedStoriesByCollection':
        return (
          <RelatedArticlesByCollection
            key={content.id}
            collectionId={content.collection._ref}
          />
        );
      case 'ad':
        return <Ad key={content.id} size={content.size} />;
      case 'divider':
        return <Divider key={content.id} variant={content.variant} />;
      case 'donateButton':
        return (
          <DonateButton
            key={content.id}
            headline={content.headline}
            subheadline={content.subheadline}
            ctaText={content.ctaText}
            ctaLink={content.ctaLink}
            useDarkModeColorScheme={content.useDarkModeColorScheme}
          />
        );
      case 'newsletterSignup':
        return (
          <NewsletterSignup
            type="newsletterWidget"
            key={content.id}
            text={content.text}
            variant={content.variant}
          />
        );
      case 'recipeBlock':
        return (
          <Recipe
            key={content.id}
            recipe={content}
            article={article as Article}
          />
        );
      case 'shareButton':
        return (
          <ShareButtonEmbed
            key={content.id}
            articleTitle={article?.title}
            anchorLinkHash={content.anchorLinkHash}
          />
        );
      case 'legacyRecipeBlock':
        return (
          <LegacyRecipe
            key={content.id}
            title={content.title}
            htmlContent={content.html}
          />
        );
      default:
        return null;
    }
  };

  const groupedArticleContents = contents && groupArticleItems(contents);

  const innerContents =
    groupedArticleContents &&
    groupedArticleContents.map((content, i) => Block(content, i, article));

  const allContent =
    children && insertChildrenAt
      ? [
          topContent,
          ...innerContents.slice(0, insertChildrenAt),
          children,
          ...innerContents.slice(insertChildrenAt),
        ]
      : [...innerContents, children];

  return <div className={className}>{allContent}</div>;
};

export default ArticleContent;
