import React from 'react';
import { useRouter } from 'next/router';
import getAuthorNames from 'utils/getAuthorNames';
import environmentIsProd from 'utils/environmentIsProd';
import { curlyQuotes } from 'utils/text';
import ShareButton from 'components/ShareButton';
import { Article, Position } from 'sharedTypes';

interface Props {
  text: string;
  variant: Position;
  article?: Article;
}

const PullQuote: React.FC<Props> = ({ text: sourceText, variant, article }) => {
  const text = curlyQuotes(sourceText);
  const { asPath } = useRouter();

  const HOST = environmentIsProd()
    ? `https://www.tabletmag.com`
    : `https://staging.tabletmag.com`;
  const currentUrl = `${HOST}${asPath}`;

  return (
    <div
      className={`PullQuote PullQuote--${variant} flex flex-col items-center pt1_5 pb3 mt1_75 mb_75 border-bottom-black`}
    >
      <p className={`PullQuote__text PullQuote--${variant}__text text-center`}>
        {text}
      </p>
      {article?.meta && (
        <ShareButton
          className="PullQuote__share-button mt3"
          articleTitle={article ? article.title : ''}
          tweetText={`"${text}"`}
          articleBrief={
            article
              ? `${article.title}\n${
                  article.meta.brief
                }\n\nQuote: ${text}\n\nBy ${getAuthorNames(
                  article.meta.authors
                )}Read Article: ${currentUrl}`
              : ''
          }
          subtleTransition={true}
        />
      )}
    </div>
  );
};

export default PullQuote;
