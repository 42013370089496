import React, { useEffect } from 'react';
import { BlockContent } from 'components/BlockContent';
import cx from 'classnames';
import getAuthorNames from 'utils/getAuthorNames';
import environmentIsProd from 'utils/environmentIsProd';
import generatePrintRecipeUrl from 'utils/generatePrintRecipeUrl';
import { Button } from 'components/base';
import ShareButton from 'components/ShareButton';
import Language from 'constants/Language';
import { curlyQuotes } from 'utils/text';

import {
  Article,
  ArticleRecipeContent,
  Recipe as IRecipe,
  Ingredient,
} from 'sharedTypes';
import { useRouter } from 'next/router';

interface Props {
  recipe: ArticleRecipeContent;
  article?: Article;
  handlePrint?: () => void;
}

const Recipe: React.FC<Props> = ({ recipe, article, handlePrint }) => {
  const router = useRouter();

  useEffect(() => {
    if (handlePrint) {
      handlePrint();
    }
  }, [handlePrint]);

  const HOST = environmentIsProd()
    ? `https://www.tabletmag.com`
    : `https://staging.tabletmag.com`;
  const currentUrl = `${HOST}${router.asPath}`;

  return (
    <div
      className={cx(
        `Recipe Recipe--${recipe.variant} my1 flex flex-col hauto`,
        {
          px1: recipe.variant === 'sidebar',
          'mb1 items-center': recipe.variant === 'full-width',
        }
      )}
    >
      <div
        className={cx(
          `Recipe--${recipe.variant}__title border-bottom-dotted-black font-700 graebenbach text-center flex justify-center text-article-body-lg w100`,
          {
            mb1: recipe.variant === 'full-width',
          }
        )}
      >
        {curlyQuotes(recipe.title)}
      </div>
      <div
        className={cx(`Recipe--${recipe.variant}__container`, {
          'flex-col flex col-12': recipe.variant === 'full-width',
        })}
      >
        <div
          className={cx(
            `Recipe--${recipe.variant}__ingredient-outer-container Recipe__ingredient-outer-container`,
            {
              'flex flex-col border-bottom-dotted-black pb2_5 mb1_25':
                recipe.variant === 'sidebar',
              'col-12 pb3': recipe.variant === 'full-width',
            }
          )}
        >
          {recipe.includedRecipes &&
            recipe.includedRecipes.map((includedRecipe: IRecipe) => (
              <div
                key={includedRecipe.title}
                className="Recipe__ingredient-container mt3 flex flex-col"
              >
                {recipe.includedRecipes.length > 1 && (
                  <p className="Recipe__ingredient-title mb1 mlauto text-section-body-sm font-700 graebenbach uppercase col-11">
                    {curlyQuotes(includedRecipe.title)}
                  </p>
                )}
                {includedRecipe.ingredients &&
                  includedRecipe.ingredients.map((ingredient: Ingredient) => (
                    <div
                      className={cx('Recipe__ingredient flex justify-between', {
                        mt_5: recipe.variant === 'sidebar',
                      })}
                      key={ingredient.details || ingredient.unit}
                    >
                      <div className="Recipe__quantity font-700 graebenbach col-2 flex">
                        {ingredient.quantity}
                      </div>
                      <div className="font-700 graebenbach col-10">
                        {ingredient.unit && <span>{ingredient.unit} </span>}
                        {ingredient.details && (
                          <span>{curlyQuotes(ingredient.details)}</span>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            ))}
        </div>
        <div
          className={cx(
            `Recipe--${recipe.variant}__directions Recipe__directions`,
            {
              'col-12 pt3 pb6': recipe.variant === 'full-width',
            }
          )}
        >
          {recipe.includedRecipes &&
            recipe.includedRecipes.map((includedRecipe: IRecipe) => (
              <div
                key={includedRecipe.title}
                className={cx(
                  'Recipe__direction-container flex flex-col col-12',
                  {
                    mt3: recipe.variant === 'sidebar',
                    mt5: recipe.variant === 'full-width',
                  }
                )}
              >
                {recipe.includedRecipes.length > 1 && (
                  <p className="Recipe__direction-title graebenbach font-700 text-article-details-sm">
                    {curlyQuotes(includedRecipe.title)}
                  </p>
                )}
                {includedRecipe.directions && (
                  <ol
                    className={cx(
                      `Recipe__direction Recipe--${recipe.variant}__direction`,
                      {
                        'Recipe__direction--single':
                          recipe.includedRecipes.length <= 1,
                      }
                    )}
                  >
                    {includedRecipe.directions &&
                      includedRecipe.directions.map((direction: string) => (
                        <li
                          className={cx(
                            'Recipe__direction-text graebenbach font-400',
                            {
                              mt1_75: recipe.variant === 'sidebar',
                              mt2: recipe.variant === 'full-width',
                            }
                          )}
                          key={direction}
                        >
                          {curlyQuotes(direction)}
                        </li>
                      ))}
                  </ol>
                )}
                {includedRecipe.notes && (
                  <div
                    className={cx(
                      `Recipe__notes Recipe--${recipe.variant}__notes graebenbach font-400 color-black`,
                      {
                        'text-article-details-xs': recipe.variant === 'sidebar',
                      }
                    )}
                  >
                    <BlockContent blocks={includedRecipe.notes} />
                  </div>
                )}
                {includedRecipe.footnotes && (
                  <div
                    className={cx(
                      `Recipe__footnotes Recipe--${recipe.variant}__footnotes graebenbach font-400`,
                      {
                        'text-article-details-xs color-gray-darker':
                          recipe.variant === 'sidebar',
                        'color-black': recipe.variant === 'full-width',
                      }
                    )}
                  >
                    <BlockContent blocks={includedRecipe.footnotes} />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      <div
        className={cx(
          'Recipe__share-container relative border-top-dotted-black w100 flex justify-center items-center',
          {
            none: handlePrint,
          }
        )}
      >
        <ShareButton
          className="Recipe__share-button absolute p0 z1 transition"
          articleTitle={article ? article.title : ''}
          articleBrief={
            article
              ? `${article.title}\n${article.meta.brief}\nBy ${getAuthorNames(
                  article.meta.authors
                )}\nRead Article: ${currentUrl}`
              : ''
          }
          subtleTransition={true}
        />
        <Button
          ariaLabel={Language.t('Recipe.printButtonAriaLabel')}
          className="Recipe__print-button bg-color-transparent transition flex items-center"
          to={generatePrintRecipeUrl(recipe, article)}
          openInNewTab={true}
          containerClassName="Recipe__print-button-container absolute"
        >
          <span className="mr_25 graebenbach font-400 text-article-details-sm color-black">
            {Language.t('Recipe.print')}
          </span>

          <div className="Recipe__arrow-icon color-black transition-short inline-block ml_25 button-move-arrow graebenbach font-400">
            &rarr;&#xFE0E;
          </div>
        </Button>
      </div>
    </div>
  );
};

export default Recipe;
