import React from 'react';
import { AudioPlayerType } from 'sharedTypes';
import { format } from 'date-fns';
import { Img } from 'components/base';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

interface Props {
  info: AudioPlayerType['linkedPodcastEpisode'];
  children?: React.ReactNode;
}

const PodcastInfo: React.FC<Props> = ({
  info: { title, description, releaseDate, podcast, image },
  children,
}) => {
  return (
    <div className="AudioPlayer__PodcastInfoContainer">
      {image && (
        <div className="podcast-info-image">
          <Img
            className="w100 radius-sm h100"
            alt={image.credit || image.alt || ''}
            sizes="(maxWidth: 400px) 400px, (maxWidth: 768px) 768px, 768px"
            src={sanityImgUtil(image, 768)}
            dimensions={image.metadata?.dimensions}
            srcSet={sanityImgSrcSetUtil(image, 400, 768, 768)}
          />
        </div>
      )}

      <div className="podcast-info-block">
        <div className="podcast-title">{podcast.title}</div>

        <div className="episode-name">{title}</div>

        <div className="episode-description">{description}</div>

        <div className="episode-date">
          {format(
            releaseDate ? new Date(releaseDate) : new Date(),
            'MMMM dd, yyyy'
          )}
        </div>

        {children}
      </div>
    </div>
  );
};

export default PodcastInfo;
